<template>
  <b-card id="cart">
    <div class="cart-title card-title mt-0">
      Order Summary
    </div>

    <b-card-text class="">
      <em v-if="cartEmpty">
        Your cart is empty.
      </em>

      <cart-item-list v-else :dashed="false" />
    </b-card-text>

    <hr>

    <div v-if="!cartEmpty" class="d-flex flex-row justify-content-between total">
      <div>
        <strong>
          <span v-if="cartTypeScope === 'LegalSpec'">
            Estimated
          </span>
          Total
        </strong>
      </div>
      <div v-if="cartTypeScope === 'LegalSpec'">
        <strong>
          {{ legalCartItemsTotal | dollars }}
        </strong>
      </div>
      <div v-else>
        <strong>
          {{ cartItemsTotal | dollars }}
        </strong>
      </div>
    </div>

    <b-form-group v-if="!hideButton">
      <div class="checkout-button-group">
        <promo-code
          v-if="showPromoCode"
          class="promo-code"
          :alignment="'left'"
          :checkout-in-progress="localProcessingCheckout"
        />
        <payment-ssl />
        <b-button
          variant="outline-primary"
          class="mx-2"
          v-if="!localProcessingCheckout && isVehicleRegistration && isAdmin"
          aria-label="Checkout without Payment button"
          :disabled="cartEmpty || localProcessingCheckout"
          @click="rootEmit('checkout-without-payment')"
        >
          <div class="d-flex justify-content-center">
            <span>Checkout without Payment</span>
          </div>
        </b-button>
        <b-button
          v-if="!localProcessingCheckout"
          class="cart-button float-right btn-preferred"
          :aria-label="buttonText + ' button'"
          :disabled="cartEmpty || localProcessingCheckout || isAgreementAccepted"
          @click="rootEmit('cart-next-page')"
        >
          {{ buttonText }}
        </b-button>
      </div>
    </b-form-group>
    <ct-centered-spinner v-if="localProcessingCheckout" class="mb-0" />
    <collect-account-info @success="rootEmit('cart-proceed-to-payment')" />
  </b-card>
</template>
<script>

import { mapGetters, mapActions } from 'vuex'
import { dollars } from '@/filters'
import CollectAccountInfo from '@/components/CollectAccountInfo'
import { extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'
import CtCenteredSpinner from '../shared/CtCenteredSpinner'
import PaymentSsl from '../shared/PaymentSsl.vue'
import PromoCode from '@/components/Checkout/PromoCode'
import CartItemList from '@/components/Checkout/CartItemList'

extend('required', {
  ...required,
  message: '{_field_} is required',
})

export default {
  name: 'Cart',
  filters: {
    dollars,
  },
  components: {
    CartItemList,
    CollectAccountInfo,
    CtCenteredSpinner,
    PaymentSsl,
    PromoCode,
  },
  props: {
    processingCheckout: {
      type: Boolean,
      default: false,
    },
    hideButton: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
      default: 'Continue Checkout',
    },
    cartTypeScope: {
      type: String,
      default: null,
    },
    showPromoCode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localProcessingCheckout: this.processingCheckout,
      updatingBundles: false,
    }
  },
  computed: {
    ...mapGetters('checkout', [
      'selectedPaymentMethod',
      'agreementAccepted',
      'cartItemsTotal',
      'cartEmpty',
      'legalCartItemsTotal',
      'productItems',
      'findProduct',
      'productIsBundle',
      'cartProductBundleItems',
    ]),
    ...mapGetters('website', [
      'website',
    ]),
    isAgreementAccepted() {
      if (this.buttonText === 'Checkout') {
        return !this.agreementAccepted
      } else {
        return false
      }
    },
    bundleInCart() {
      return this.cartProductBundleItems?.length
    },
    isVehicleRegistration() {
      const path = this.$route.path
      return path.includes('vehicle-registrations')
    },
    isAdmin() {
      return sessionStorage.getItem('admin-logged-in') === 'true'
    },
  },
  watch: {
    processingCheckout(newVal) {
      this.localProcessingCheckout = newVal
    },
    // Watches for a change in cart items and recalculates product bundle trials if needed and updates bundle products accordingly.
    // Logic limited to only BIZID bundle as it was causing issues with $39 formation bundle.
    // TODO: When this logic needs opened to other future product bundles similar to biz id it will need a way to
    // differentiate simple product bundles formatted like biz id and complicated filing addon bundles that operate differently.
    productItems: {
      async handler (newItems, oldItems) {
        if (newItems.length === oldItems.length) return

        const oldBundleCount = oldItems.filter(item => this.productIsBundle(item)).length
        const newBundleCount = newItems.filter(item => this.productIsBundle(item)).length
        if (this.updatingBundles || (oldBundleCount === 0 && newBundleCount === 1) || !this.bundleInCart) return

        this.updatingBundles = true
        try {
          const bundleUpdatePromises = this.productItems
            .filter(item => this.productIsBundle(item) && this.isBizIdBundle(item))
            .map(item => this.updateBundle(item))

          await Promise.all(bundleUpdatePromises)
        } finally {
          this.updatingBundles = false
        }
      },
    },
  },
  methods: {
    ...mapActions('checkout', [
      'payInvoices',
      'checkForBundleFreeTrialsAndCalculateTotal',
      'updateCartItemData',
    ]),
    rootEmit(event) {
      this.$root.$emit(event)
    },
    async updateBundle(item) {
      const updatedBundle = await this.checkForBundleFreeTrialsAndCalculateTotal({ productBundle: this.findProduct(item.product_id) })

      const nestedProducts = updatedBundle.products.reduce((acc, product) => ({
        ...acc,
        [product.id]: { ...product },
      }), {})

      await this.updateCartItemData({ id: item.id, data: nestedProducts })
    },
    isBizIdBundle(item)
    {
      return this.findProduct(item.product_id).product_categorization?.subcategory === 'business_identity'
    },
  },
}
</script>

<style lang="scss" scoped>
  #cart {
    .cart-title {
      font-size: 1.375em;
      font-weight: 700;
    }

    .card-body {
      padding: 2.25rem;
    }

    .cart-button {
      margin: 1.25em 0 0 0;
    }

    .total {
      padding-right: 1.25em;
    }

    hr {
      color: black;
    }

    .payment-ssl-badge {
      margin: 0.5em 3.5em 0 0;
      width: 6.125em;
    }

    .checkout-button-group {
      display: flex;
      justify-content: flex-end;
      align-items: end;

      .promo-code {
        margin-right: auto;
      }
    }

    @media only screen and (max-width: 768px) {
      .checkout-button-group {
        justify-content: center;
        flex-wrap: wrap;
      }
    }

    @media only screen and (max-width: 460px) {
      .payment-ssl-badge {
        width: 5.5em;
        right: 0;
        bottom: 40px;
        left: 10%;
      }

      .checkout-button-group {
        flex-wrap: wrap;
        justify-content: center;
      }
    }
  }
</style>
